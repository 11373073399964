<template>
  <div>
    <directors-data-form v-if="addSocio" @closeModal="close" :partnerId="partnerId"></directors-data-form>
    <p class="title">Quadro societário</p>
    <p class="subtitle">Você deve adicionar todos os sócios que constam no Contrato Social da Empresa.</p>
    <p class="subtitle"><strong>ATENÇÃO MEI:</strong> Para concessão de crédito é necessário incluir pelo menos 1 avalista. </p>

    <button @click.prevent="addSocio = !addSocio" class="form_title flex items-center focus:outline-none">
      <i class="fas fa-plus bg-secondary rounded-full h-8 w-8 mr-4 flex justify-center items-center text-xs lg:text-sm text-white"></i>
      ADICIONAR SÓCIO/AVALISTA
    </button>
    <form class="relative" @submit.prevent="submit">
      <div class="backdrop" v-show="loadingPage"><div class="fas fa-dollar-sign spin"></div></div>
      <div class="w-full mt-6">
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th class="hidden lg:table-cell">CPF</th>
              <th class="hidden lg:table-cell">Tipo Sócio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="item in socios" :key="item.id">
                <td>{{item.nomeCompleto}}
                      <span v-if="item.tipoSocio === 'socio-solicitante'"></span> <span v-if="item.tipoSocio === 'socio'"><sup><a href="javascript://" v-on:click="definirSolicitante(item.id)">[ DEFINIR COMO SOLICITANTE ]</a></sup></span>
                </td>
                <td class="hidden lg:table-cell">{{item.cpf}}</td>
                <td class="hidden lg:table-cell">{{(item.tipoSocio == 'socio-solicitante' ? 'Solicitante' : item.tipoSocio == 'avalista' ? 'Avalista' : 'Sócio')}}</td>
                <td v-if="item.tipoSocio !== 'socio-solicitante'" class="hidden lg:table-cell">
                  <button class="text-blue-600 focus:outline-none" @click.prevent="editPartner(item.id)">Editar</button>
                  <span class="mx-1 text-gray-500 text-sm"> | </span>
                  <button class="text-blue-600 focus:outline-none" @click.prevent="confirmDialog = true;removeId = item.id">Remover</button>
                </td>
                <td v-if="item.tipoSocio !== 'socio-solicitante'" class="table-cell lg:hidden">
                  <button class="w-3 mr-1 focus:outline-none" @click.prevent="editPartner(item.id)"><i class="fas fa-edit text-blue-600"></i></button>
                  <span class="mx-1 text-gray-500 text-xs"> | </span>
                  <button class="w-3 focus:outline-none" @click.prevent="confirmDialog = true;removeId = item.id"><i class="fas fa-trash-alt text-red-600"></i></button>
                </td>
              </tr>
              <tr v-if="!socios.length > 0">
                <td colspan="3">Nenhum sócio adicionado</td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="boxes_group mt-4">
        <p class="radio_label mb-2">Declaro que as informações acima são a expressão da verdade e autorizo a Instituição a consultar órgãos especificos de proteção ao crédito e central de risco de crédito do Banco Central do Brasil, permitindo a análise dos créditos a conceder, o arquivamento de meus dados cadastrais, idoneidade e histórico, assim como o fornecimento de informações e de performance de pagamento de minhas operações para a Central de Risco de Crédito, administrada pelo Banco Central do Brasil, Associações Comerciais, SERASA e demais órgãos similares, públicos ou privados , os quais poderão deles se utilizar, respeitadas as disposições legais vigentes. Declaro ainda estar ciente de que deverei informar qualquer alteração à instituição nas informações acima prestadas.</p>
        <v-radio class="inline" name="termos" v-model="termos" label="Sim" :value="true" v-validate="'required:true'"></v-radio>
        <v-radio class="inline" name="termos" v-model="termos" label="Não" :value="false"></v-radio>
        <p class="text-xs text-red-600">{{errors.first("termos")}}</p>
      </div>
      <div class="row justify-center">
        <v-button color="primary" class="my-4" size="lg" :loading="loading">Salvar e Avançar</v-button>
      </div>
    </form>
    <confirm-dialog v-if="confirmDialog" @closeModal="confirmDialog = false;removeId = ''" @confirm="removePartner">
      <p class="dialog_title">Atenção</p>
      <p class="dialog_text">Deseja realmente excluir este sócio?</p>
    </confirm-dialog>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VRadio from '@/components/Inputs/Radio'
import Partner from '@/services/Business/Partner'
import DirectorsDataForm from '@/components/Business/Modal/DirectorsDataForm'
import ConfirmDialog from '@/components/Modal/ConfirmDialog'

export default {
  name: 'directors-data',
  components: { VButton, VRadio, DirectorsDataForm, ConfirmDialog },
  data () {
    return {
      termos: false,
      addSocio: false,
      loading: false,
      loadingPage: true,
      partnerId: '',
      socios: [],
      confirmDialog: false,
      removeId: ''
    }
  },
  mounted () {
    this.getPartners()
  },
  methods: {
    async getPartners () {
      try {
        const response = (await Partner.getPartners()).data.data
        this.socios = response
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPage = false
      }
    },
      async definirSolicitante (id) {
      try {
        const response = (await Partner.setSolicitante(id)).data.data
        await this.getPartners();
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPage = false
      }
    },
    async removePartner () {
      try {
        this.confirmDialog = false
        // await Partner.removePartner(id)
        this.$store.dispatch('notification', { type: 'success', message: 'Sócio removido com sucesso!' })
        this.removeId = ''
        this.getPartners()
        this.$emit('updateBusiness')
      } catch (error) {
        this.confirmDialog = false
        this.$store.dispatch('notification', { type: 'error', message: 'Ocorreu um erro ao remover o sócio' })
        this.removeId = ''
        console.error(error)
      }
    },
    editPartner (id) {
      this.partnerId = id
      this.addSocio = !this.addSocio
    },
    close (value) {
      this.addSocio = false
      this.partnerId = ''
      if (value) {
        this.getPartners()
        this.$emit('updateBusiness')
      }
    },
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await Partner.finishPartnerData()
            this.$store.dispatch('notification', { type: 'success', message: 'Dados salvos com sucesso!' })
            this.$emit('updateBusiness')
            this.$router.push('/tomador/perfil/documentos')
          } catch (error) {
            this.loading = false

          let erros = "";
          for(var e in error.response.data.erros) {
            erros += error.response.data.erros[e]+ ' ';
          }

          this.$store.dispatch('notification', { type: 'error', message: erros })
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-primary text-center mt-4 mb-8
  @screen lg
    @apply text-2xl

.form_title
  @apply font-bold text-base text-black mt-6
  @screen lg
    @apply text-xl

.row
  @apply block flex flex-col items-center
  @screen lg
    @apply flex flex-row flex-wrap

.subtitle
  @apply text-sm opacity-75

.label
  @apply text-sm opacity-75 cursor-pointer mr-2

.radio
  @apply mr-1 my-auto

.text
  @apply text-sm opacity-75 mb-2

// Tabela
table
  @apply w-full border-none
  th
    @apply py-1 opacity-75 font-bold text-sm text-left
  tr
    @apply py-1 border-t border-b border-gray-200
    &:hover
      @apply bg-gray-100
  td
    @apply py-1 opacity-75 text-sm self-start

.backdrop
  @apply absolute w-full h-full bg-white text-center text-4xl text-primary
  z-index: 60
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>

<template>
  <transition name="fade">
    <div class="modal">

      <div class="container h-full flex flex-col items-center justify-center">
        <div class="content">
          <p class="close" @click="$emit('closeModal')"> <i class="fas fa-times text-lg"></i> </p>
          <p class="title">{{ partnerId ? 'Atualizar' : 'Adicionar' }} Sócio/Avalista</p>
          <p style="text-align: center; color: #555" v-show="loadingPage">Carregando informações...
            <v-button color="" disabled="disabled" size="lg" :loading="loadingPage"></v-button>
          </p>

          <div v-show="!loadingPage">
            <p class="form_title">DADOS PESSOAIS</p>
            <div class="row">
              <v-input class="w-full" name="nomeCompleto" v-model="socio.nomeCompleto"
                :error="errors.first('nomeCompleto')" label="Nome Completo*" v-validate="'required|fullName'"></v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="cpf" v-model="socio.cpf" :error="errors.first('cpf')"
                label="CPF*" mask="###.###.###-##" v-validate="'required|cpf'"></v-input>
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="dataNascimento" v-model="socio.dataNascimento"
                :error="errors.first('dataNascimento')" mask="##/##/####" :masked="true" label="Data de Nascimento*"
                v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="sexo" v-model="socio.sexo" :error="errors.first('sexo')"
                label="Sexo*" :options="sexos" v-validate="{ required: true, is_not: -1 }" itemText="descricao"
                itemValue="id"></v-input>
              <v-input class="w-full lg:w-1/4" name="estadoCivil" v-model="socio.estadoCivil"
                :error="errors.first('estadoCivil')" label="Estado Civil*" :options="estadosCivis"
                v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="id"></v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-1/3 lg:pr-6" name="nacionalidade" v-model="socio.nacionalidade"
                :error="errors.first('nacionalidade')" label="Nacionalidade*" :options="computedNacionalidades"
                v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="descricao"></v-input>
              <v-input v-if="isBR" class="w-full lg:w-1/3 lg:pr-6" name="ufNascimento" v-model="socio.ufNascimento"
                :error="errors.first('ufNascimento')" label="UF de Nascimento*" :options="computedUfs"
                v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="descricao"></v-input>
              <v-input v-else class="w-full lg:w-1/3 lg:pr-6" name="ufNascimento" v-model="socio.ufNascimento"
                :error="errors.first('ufNascimento')" label="UF de Nascimento*" v-validate="'required|length:2'">
              </v-input>
              <v-input v-if="isBR" class="w-full lg:w-1/3 lg:pr-6" name="cidadeNascimento"
                v-model="socio.cidadeNascimento" :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*"
                :options="computedCities" v-validate="{ required: true, is_not: -1 }" itemText="descricao"
                itemValue="descricao" autocomplete="off"></v-input>
              <v-input v-else class="w-full lg:w-1/3 lg:pr-6" name="cidadeNascimento" v-model="socio.cidadeNascimento"
                :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*" v-validate="'required|min:2'">
              </v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="rg" v-model="socio.rg" :error="errors.first('rg')"
                label="RG*" v-validate="'required'"></v-input>
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="dataExpedicaoRg" v-model="socio.dataExpedicaoRg"
                :error="errors.first('dataExpedicaoRg')" mask="##/##/####" :masked="true" label="Data de Expedição RG*"
                v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
              <v-input class="w-full lg:w-1/4 lg:pr-6" name="orgaoExpedicaoRG" v-model="socio.orgaoExpedicaoRG"
                :error="errors.first('orgaoExpedicaoRG')" label="Órgão de Expedição RG*" v-validate="'required|min:2'">
              </v-input>
              <v-input class="w-full lg:w-1/4" name="ufExpedicaoRg" v-model="socio.ufExpedicaoRg"
                :error="errors.first('ufExpedicaoRg')" label="UF RG*" mask="AA" v-validate="'required|length:2'">
              </v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-1/1" name="email" v-model="socio.email" :error="errors.first('email')"
                label="E-mail pessoal*" v-validate="'required|email'"></v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-3/12 lg:pr-6" mask="(##) #####-####" :masked="true" name="celular"
                v-model="socio.celular" :error="errors.first('celular')" label="Celular*"
                v-validate="'required|min:14|max:15'"></v-input>
              <v-input class="w-full lg:w-3/12 lg:pr-6" mask="(##) ####-####" :masked="true" name="telefoneResidencial"
                v-model="socio.telefoneResidencial" :error="errors.first('telefoneResidencial')"
                label="Telefone Residencial" v-validate="'min:14|max:15'"></v-input>
              <v-input class="w-full lg:w-3/12 lg:pr-6" name="tipoResidencia" v-model="socio.tipoResidencia"
                :error="errors.first('tipoResidencia')" label="Tipo Residência*" :options="tiposResidencia"
                v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="id"></v-input>
              <div class="w-full lg:w-3/12 flex">
                <v-input class="w-full lg:w-7/12" name="tempoResidencia" v-model="socio.tempoResidencia"
                  :error="errors.first('tempoResidencia')" label="Tempo de residência*" mask="##"
                  v-validate="'required|regex:^[0-9][0-9]?$'"></v-input>
                <v-input class="w-full lg:w-5/12" name="tempoResidenciaAnosMeses" v-model="socio.tempoResidenciaAnosMeses"
                  :error="errors.first('tempoResidenciaAnosMeses')" label="" :options="temposResidencia"
                  v-validate="'required'" itemText="descricao" itemValue="id"></v-input>
              </div>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-1/2 lg:pr-6" name="nomeMae" v-model="socio.nomeMae"
                :error="errors.first('nomeMae')" label="Nome Mãe*" v-validate="'required|fullName'"></v-input>
              <v-input class="w-full lg:w-1/2" name="nomePai" v-model="socio.nomePai" :error="errors.first('nomePai')"
                label="Nome Pai"></v-input>
            </div>
            <v-input class="w-full lg:w-1/1 lg:pr-6" name="nomeConjugeCompanheiro" v-model="socio.nomeConjugeCompanheiro"
              :error="errors.first('nomeConjugeCompanheiro')" label="Nome Cônjuge" v-validate="validateConjuge"></v-input>
            <div class="row">
              <v-input class="w-full lg:w-1/3 lg:pr-6" name="cargo" v-model="socio.cargo" :error="errors.first('cargo')"
                label="Cargo*" v-validate="'required|min:2'"></v-input>
              <v-input class="w-full lg:w-1/3" name="salario" v-model="socio.salario" :error="errors.first('salario')"
                label="Salário" type="money" v-validate="'decimal:2|min_value:1|max_value:2147483647'"></v-input>
              <v-input class="w-full lg:w-1/3 lg:pr-6" name="porcentagemParticipacao"
                v-model.number="socio.porcentagemParticipacao" :error="errors.first('porcentagemParticipacao')"
                label="Porcentagem de Participação" :mask="['# %', '## %', '#,## %', '##,## %', '###,## %']"
                v-validate="'required|decimal:2|min_value:0|max_value:10000'" @blur="checkPercentage"></v-input>
            </div>
            <div class="row">
              <div class="self-start lg:w-1/2">
                <div class="boxes_group">
                  <p class="subtitle">Que tipo de sócio você é?</p>
                  <v-radio class="inline text-sm" name="socioAdministrador" v-model="socio.socioAdministrador"
                    label="Proprietário e/ou Administrador" :value="true" v-validate="'required'"></v-radio> <br />
                  <v-radio class="inline text-sm" name="socioAdministrador" v-model="socio.socioAdministrador"
                    label="Quotista ou Avalista" :value="false"></v-radio>
                  <p class="text-xs text-red-600">{{ errors.first("socioAdministrador") }}</p>
                </div>
              </div>

              <div class="self-start lg:w-1/2" v-if="!socio.socioAdministrador">
                <div class="boxes_group">
                  <p class="subtitle">É sócio ou será avalista da operação?</p>
                  <div>
                    <v-radio class="inline text-sm" name="avalista" v-model="socio.apenasAvalistaNaoFazParteQSA"
                      label="Avalista do crédito" :value="true" v-validate="'required'"></v-radio>
                    <v-radio class="inline text-sm" name="avalista" v-model="socio.apenasAvalistaNaoFazParteQSA"
                      label="Sócio" :value="false"></v-radio>
                    <p class="text-xs text-red-600">{{ errors.first("pessoaPoliticamenteExposta") }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <v-input type="money" class="lg:w-1/3" name="patrimonioAproximado" label="Valor Patrimonio Aproximado"
                v-model="socio.patrimonioAproximado" :error="errors.first('patrimonioAproximado')"
                v-validate="'decimal:2|min_value:1|max_value:2147483647'"></v-input>
              <v-input class="w-full lg:w-1/3 lg:pr-6" name="dataEntradaSociedade"
                :readonly="socio.apenasAvalistaNaoFazParteQSA" v-model="socio.dataEntradaSociedade"
                :error="errors.first('dataEntradaSociedade')" mask="##/##/####" :masked="true"
                label="Data de Entrada na Sociedade*" v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
              <v-input class="w-full lg:w-1/3 lg:pr-6" name="dataSaidaSociedade"
                :readonly="socio.apenasAvalistaNaoFazParteQSA" v-model="socio.dataSaidaSociedade"
                :error="errors.first('dataSaidaSociedade')" mask="##/##/####" :masked="true"
                label="Data de Saída na Sociedade" v-validate="'date_format:dd/MM/yyyy'"></v-input>
            </div>
            <p class="form_title">ENDEREÇO</p>
            <div class="row">
              <v-input @blur="getCep" class="w-full lg:w-2/12 lg:pr-6" mask="#####-###" :masked="true" name="endereco_CEP"
                v-model="socio.endereco_CEP" :error="errors.first('endereco_CEP')" label="CEP*"
                v-validate="'required|length:9'"></v-input>
              <v-input class="w-full lg:w-6/12 lg:pr-6" name="endereco_Logradouro" v-model="socio.endereco_Logradouro"
                :error="errors.first('endereco_Logradouro')" label="Logradouro*" v-validate="'required|min:5'">
              </v-input>
              <v-input class="w-full lg:w-2/12 lg:pr-6" name="endereco_Numero" v-model="socio.endereco_Numero"
                :error="errors.first('endereco_Numero')" label="Número*" v-validate="'required|min:1'"></v-input>
              <v-input class="w-full lg:w-2/12" name="endereco_Bairro" v-model="socio.endereco_Bairro"
                :error="errors.first('endereco_Bairro')" label="Bairro*" v-validate="'required|min:2'"></v-input>
            </div>
            <div class="row">
              <v-input class="w-full lg:w-6/12 lg:pr-6" name="endereco_Complemento" v-model="socio.endereco_Complemento"
                :error="errors.first('endereco_Complemento')" label="Complemento" v-validate="'min:2'"></v-input>
              <v-input class="w-full lg:w-2/12 lg:pr-6" @input="getCities" :options="ufs" itemText="name" itemValue="name"
                name="endereco_UF" v-model="socio.endereco_UF" :error="errors.first('endereco_UF')" label="UF"
                v-validate="{ required: true, is_not: -1 }"></v-input>
              <v-input class="w-full lg:w-4/12" :options="cities" itemText="name" itemValue="name" name="endereco_Cidade"
                v-model="socio.endereco_Cidade" :error="errors.first('endereco_Cidade')" label="Cidade"
                v-validate="{ required: true, is_not: -1 }" :disabled="!cities.length"></v-input>
            </div>
            <p class="form_title">REFERÊNCIA PESSOAL</p>
            <div class="row">
              <v-input class="w-full lg:w-1/2 lg:pr-6" name="referenciaPessoal1_Nome"
                v-model="socio.referenciaPessoal1_Nome" :error="errors.first('referenciaPessoal1_Nome')"
                label="Nome Completo*" v-validate="'required|fullName'"></v-input>
              <v-input class="w-full lg:w-1/2" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
                name="referenciaPessoal1_Telefone" v-model="socio.referenciaPessoal1_Telefone"
                :error="errors.first('referenciaPessoal1_Telefone')" label="Telefone*"
                v-validate="'required|min:14|max:15'"></v-input>
            </div>

            <p class="form_title">PESSOA EXPOSTA POLITICAMENTE</p>
            <div>
              <p class="text">Conforme definido pela Circular nº 3.461 de 24/07/2009 do Banco Central do Brasil, as
                instituições financeiras devem obter de seus clientes permanentes informações que permitam
                caracterizá-los ou não como pessoas expostas politicamente (PEP). Consideram-se PEP os agentes públicos
                que desempenham ou tenham desempenhado, nos últimos cinco anos, no Brasil ou em países, territórios e
                dependências estrangeiros, cargos, empregos ou funções públicas relevantes, assim como seus
                representantes, familiares e outras pessoas de seu relacionamento próximo.</p>
              <v-radio class="inline text-sm" name="pessoaPoliticamenteExposta" v-model="socio.pessoaPoliticamenteExposta"
                label="Sim" :value="true" v-validate="'required'"></v-radio>
              <v-radio class="inline text-sm" name="pessoaPoliticamenteExposta" v-model="socio.pessoaPoliticamenteExposta"
                label="Não" :value="false"></v-radio>
              <p class="text-xs text-red-600">{{ errors.first("pessoaPoliticamenteExposta") }}</p>
            </div>
            <div class="row justify-center">
              <v-button color="error" class="mx-2 hidden lg:block" @click="$emit('closeModal')" size="md"
                :loading="loading">Voltar</v-button>
              <v-button color="primary" class="mt-2 lg:mb-2 lg:mx-2" @click="submit" size="md" :loading="loading">
                {{ partnerId ? 'Atualizar' : 'Adicionar' }}</v-button>
              <v-button color="error" class="lg:hidden" @click="$emit('closeModal')" size="md" :loading="loading">Voltar
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VInput from '@/components/Inputs/Input'
import VRadio from '@/components/Inputs/Radio'
import CapitalizeName from '@/mixins/capitalizeName'

import Cep from '@/services/Commons/Cep'
import Forms from '@/services/Business/Forms'
import Partner from '@/services/Business/Partner'

export default {
  components: { VButton, VInput, VRadio },
  props: ['partnerId'],
  mixins: [CapitalizeName],
  data() {
    return {
      socio: {
        nomeCompleto: '',
        cpf: '',
        dataNascimento: '',
        dataEntradaSociedade: '',
        dataSaidaSociedade: '',
        nacionalidade: '',
        ufNascimento: '',
        cidadeNascimento: '',
        rg: '',
        dataExpedicaoRg: '',
        orgaoExpedicaoRG: '',
        ufExpedicaoRg: '',
        sexo: -1,
        estadoCivil: -1,
        nomeConjugeCompanheiro: '',
        email: '',
        apenasAvalistaNaoFazParteQSA: false,
        endereco_CEP: '',
        endereco_Logradouro: '',
        endereco_Numero: '',
        endereco_Bairro: '',
        endereco_Complemento: '',
        endereco_Cidade: -1,
        endereco_UF: -1,

        celular: '',
        telefoneResidencial: null,
        tipoResidencia: -1,
        tempoResidencia: '',
        tempoResidenciaAnosMeses: 1,
        nomePai: '',
        nomeMae: '',
        cargo: '',
        salario: 0,
        socioAdministrador: '',
        porcentagemParticipacao: '',
        patrimonioAproximado: '',
        referenciaPessoal1_Nome: '',
        referenciaPessoal1_Telefone: '',

        pessoaPoliticamenteExposta: ''
      },

      sexos: [],
      estadosCivis: [],
      tiposResidencia: [],
      temposResidencia: [],
      cities: [],
      ufs: [],
      firstLoad: true,
      isBR: false,
      nacionalidades: [],
      ufsNascimento: [],
      citiesNascimento: [],

      loading: false,
      loadingPage: true,

      error: null
    }
  },
  computed: {
    validateConjuge() {
      if (this.estadosCivis.length) {
        const currentMaritalState = this.estadosCivis.find(estado => estado.id === this.socio.estadoCivil)
        if (currentMaritalState) return currentMaritalState.descricao.toLowerCase() === 'casado' ? 'required|fullName' : ''
      }
      return ''
    },
    computedNacionalidade() {
      return this.socio.nacionalidade
    },
    computedUF() {
      return this.socio.ufNascimento
    },
    computedNacionalidades() {
      return this.nacionalidades.map(n => ({ descricao: n }))
    },
    computedUfs() {
      return this.ufsNascimento.map(uf => ({ descricao: uf }))
    },
    computedCities() {
      return this.citiesNascimento.map(city => ({ descricao: city }))
    }
  },
  methods: {
    async getCep() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.socio.endereco_CEP)) {
        try {
          const { cidade, uf, complemento, logradouro, bairro } = (await Cep.getCep(this.socio.endereco_CEP)).data.data
          await this.getCities(uf)
          this.socio = { ...this.socio, endereco_Cidade: cidade, endereco_UF: uf, endereco_Complemento: complemento, endereco_Logradouro: logradouro, endereco_Bairro: bairro }
        } catch (error) {
          console.error(error)
        }
      }
    },
    async getUfs() {
      try {
        this.ufsNascimento = (await Cep.getUfs()).data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getCitiesNascimento(uf, cidade) {
      try {

        if (this.nacionalidade == null) this.nacionalidade = 'Brasileiro';

        this.citiesNascimento = (await Cep.getCities(uf)).data.data
        this.cidadeNascimento = cidade
      } catch (err) {
        console.error(err)
      }
    },
    async getCities(uf) {
      try {
        const cities = (await Cep.getCities(uf || this.socio.endereco_UF)).data.data
        this.cities = cities.map(city => ({ name: city }))
      } catch (err) {
        console.error(err)
      }
    },
    checkPercentage() {
      if (this.socio.porcentagemParticipacao > 10000) this.socio.porcentagemParticipacao = 10000
    },
    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            if (this.partnerId) {
              await Partner.editPartner({
                ...this.socio,
                nomeCompleto: this.capitalizeName(this.socio.nomeCompleto),
                nomeConjugeCompanheiro: this.capitalizeName(this.socio.nomeConjugeCompanheiro),
                nomeMae: this.capitalizeName(this.socio.nomeMae),
                nomePai: this.socio.nomePai !== '' ? this.capitalizeName(this.socio.nomePai) : undefined,
                referenciaPessoal1_Nome: this.capitalizeName(this.socio.referenciaPessoal1_Nome),
                porcentagemParticipacao: (+this.socio.porcentagemParticipacao) / 100,
                telefoneResidencial: this.socio.telefoneResidencial && this.socio.telefoneResidencial.trim().length ? this.socio.telefoneResidencial : null
              })
              this.$store.dispatch('notification', { type: 'success', message: 'Sócio atualizado com sucesso' })
            } else {
              await Partner.addPartner({ ...this.socio, porcentagemParticipacao: (+this.socio.porcentagemParticipacao) / 100 })
              this.$store.dispatch('notification', { type: 'success', message: 'Sócio adicionado com sucesso' })
            }
            this.$emit('closeModal', true)
          } catch (error) {
            this.loading = false
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
            this.error = error.response.data.erros
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  watch: {
    'socio.socioAdministrador'() {
      if (this.socio.socioAdministrador) {
        this.socio.apenasAvalistaNaoFazParteQSA = false
        this.socio.dataEntradaSociedade = new Date().toLocaleString().substr(0, 10)
      }

    },
    computedNacionalidade() {
      this.isBR = this.socio.nacionalidade === 'Brasileiro'
      if (this.isBR) {
        if (this.firstLoad) {
          this.getUfs()
          if (this.socio.ufNascimento !== '') this.getCitiesNascimento(this.socio.ufNascimento, this.socio.cidadeNascimento)
          this.firstLoad = false
        } else {
          this.socio.ufNascimento = -1
          this.socio.cidadeNascimento = -1
        }
      } else {
        if (!this.firstLoad) {
          this.socio.ufNascimento = ''
          this.socio.cidadeNascimento = ''
        }
      }
    },
    computedUF() {
      if (this.isBR && this.socio.ufNascimento !== '' && this.socio.ufNascimento !== -1) {
        this.getCitiesNascimento(this.socio.ufNascimento, this.socio.cidadeNascimento)
      }
    }
  },
  async mounted() {
    try {
      const enums = (await Forms.getEnums()).data.data
      this.sexos = enums.sexo
      this.tiposResidencia = enums.tipoResidencia
      this.temposResidencia = enums.tempoResidencia
      this.estadosCivis = enums.estadoCivil
      this.nacionalidades = enums.nacionalidades
      if (!this.socio.nacionalidade) this.socio.nacionalidade = 'Brasileiro'
    } catch (err) {
      console.error(err)
    }

    try {
      const ufs = (await Cep.getUfs()).data.data
      this.ufs = ufs.map(uf => ({ name: uf }))
    } catch (err) {
      console.error(err)
    }

    if (this.partnerId) {
      try {

        this.loadingPage = true
        const response = (await Partner.getPartner(this.partnerId)).data.data
        if (response) {
          if (response.endereco_UF) {
            await this.getCities(response.endereco_UF)
          }

          response.porcentagemParticipacao = response.porcentagemParticipacao * 100;

          Object.keys(response).forEach(key => {
            if (response[key] !== null && response[key] !== '') this.socio[key] = response[key]
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loadingPage = false
      }
    } else {
      this.loadingPage = false
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed inset-0 mx-auto my-auto z-50
  background: rgba(29,26,26,.50)

.content
  @apply relative px-6 pt-4 w-full my-4 bg-white overflow-auto rounded-lg shadow-lg
  @screen lg
    @apply w-11/12 h-152 my-0

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary

.close
  @apply absolute right-0 top-0 opacity-75 rounded-full mr-4 mt-4 cursor-pointer text-secondary

.card
  @apply bg-white px-4 mb-4 rounded-lg border shadow

.title
  @apply font-bold text-2xl text-primary text-center mt-8 mb-8
  @screen lg
    @apply text-3xl mt-4

.form_title
  @apply font-bold text-base text-black mt-6
  @screen lg
    @apply text-xl

.row
  @apply block flex flex-col items-center
  @screen lg
    @apply flex flex-row flex-wrap

.subtitle
  @apply text-sm opacity-75

.label
  @apply text-sm opacity-75 cursor-pointer mr-2

.radio
  @apply mr-1 my-auto

.text
  @apply text-sm opacity-75 mb-2

.backdrop
  @apply absolute w-full h-full bg-white text-center text-4xl text-primary
  z-index: 60
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>

<template>
  <div class="modal border-r-2">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="content p-4">
      <slot/>
      <div class="flex justify-center">
        <v-button class="flex justify-center mr-1" size="sm" color="default" @click="$emit('closeModal')" :loading="loading">Fechar</v-button>
        <v-button class="flex justify-center ml-1" size="sm" @click="$emit('confirm')" :loading="loading">Confirmar</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'

export default {
  name: 'cofirm-dialog',
  components: { VButton },
  props: ['loading']
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full mx-auto bg-white z-50 flex flex-col justify-center
  min-height: 12rem
  @screen md
    @apply w-7/12 flex flex-col items-center
  @screen lg
    @apply w-4/12 flex flex-col items-center

.dialog
  &_title
    @apply font-bold text-primary text-center text-xl
  &_text
    @apply text-center mt-4 mb-2

</style>

import { render, staticRenderFns } from "./DirectorsDataForm.vue?vue&type=template&id=516bf4c0&scoped=true&"
import script from "./DirectorsDataForm.vue?vue&type=script&lang=js&"
export * from "./DirectorsDataForm.vue?vue&type=script&lang=js&"
import style0 from "./DirectorsDataForm.vue?vue&type=style&index=0&id=516bf4c0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516bf4c0",
  null
  
)

export default component.exports
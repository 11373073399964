import api from '@/plugins/axios'

const base = 'v1/tomador/'

export default {
    getPartners() {
        return api.get(`${base}socios`)
    },
    getPartner(id) {
        return api.get(`${base}socio/${id}`)
    },
    addPartner(socio) {
        return api.post(`${base}adicionar-socio`, socio)
    },
    editPartner(socio) {
        return api.put(`${base}editar-socio`, socio)
    },
    removePartner(socioId) {
        return api.delete(`${base}remover-socio/${socioId}`)
    },
    finishPartnerData() {
        return api.post(`${base}finalizar-preenchimento-dados`)
    },
    setSolicitante(socioId) {
        return api.put(`${base}definir-solicitante`, { socioId })
    },
}